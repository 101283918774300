import '../styles/globals.css';
import '../styles/algolia.css'
import { GoogleTagManager } from '@next/third-parties/google'
import NextNprogress from 'nextjs-progressbar';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false;
import ScrollToTopButton from '../components/Layouts/ScrollToTopButton';
import { v4 as uuidv4 } from 'uuid';
import { Provider } from 'react-redux';
import store from '../components/Store/store';
import { useEffect } from 'react';
import Script from 'next/script';
import Head from 'next/head';
import CanonicalTag from '../components/Layouts/CanonicalTag'
import { useRouter } from 'next/router';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


export default function App({ Component, pageProps: { session, ...pageProps } }) {

  const router = useRouter();






  useEffect(() => {
    if (localStorage.getItem('path_1')) {
      localStorage.setItem('path_1', localStorage.getItem('path_2'));
      localStorage.setItem('path_2', window.location.href);
    } else {
      localStorage.setItem('path_1', window.location.href);
      localStorage.setItem('path_2', window.location.href);
    }

    if (localStorage.getItem('access_token')) {

    } else {
      const new_id = uuidv4();
      localStorage.setItem('access_token', new_id)
    }


    const checkDeviceType = () => {
      if (window.matchMedia('(max-width: 767px)').matches) {
        localStorage.setItem('device', 'mobile')
      } else if (window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches) {
        localStorage.setItem('device', 'tablet')
      } else {
        localStorage.setItem('device', 'desktop')
      }
    };

    checkDeviceType();
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    mediaQuery.addEventListener('change', checkDeviceType);

  }, [pageProps])
  

  return (
    <>
      <Provider store={store}>
        <CanonicalTag />
        <Head>
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="naver-site-verification" content="5fd18914b9cfe263bb1b64fa155de305528e16d4" />
          <title>{pageProps?.title}</title>
          <meta name="description" content={pageProps?.description} />
          <meta name="keywords" content={pageProps?.keywords} />
          <link rel="apple-touch-icon" href="https://assets.delveinsight.com/png/delve-favicon.png" type="image/png" />
          <link rel="shortcut icon" href="https://assets.delveinsight.com/png/delve-favicon.png" type="image/png" />
          {/*  Yandex-site-verification  */}
          {/* <meta name="yandex-verification" content="784b07ceaf132b92" /> */}
          <meta name="yandex-verification" content="f1d711d11676531b" />
          <meta property="og:title" content={pageProps?.title} />
          <meta property="og:image" content="https://assets.delveinsight.com/img/delveinsight.png" />
          <meta property="og:image:secure_url" content="https://assets.delveinsight.com/img/delveinsight.png" />
          <meta property="og:type" content="website" />
          {/*  Twitter cards  */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image" content="https://assets.delveinsight.com/img/delveinsight.png" />
          <meta name="twitter:site" content="@delve_insight" />
          <meta name="google-site-verification" content="hH_dRptj_rM-_DekPa6kdZP1K2Szwv0_9omQ-PLk7Z4" />
          <meta name="robots" content="index, follow"></meta>
          {(pageProps?.schema) &&
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: pageProps?.schema != null ?
                  pageProps?.schema.replace(/<script[^>]*>|<\/script>/g, '') : null
              }}
            />
          }
         


        </Head>


        {(process.env.ENV != 'local') &&
          <GoogleTagManager gtmId="GTM-N5JKV9K" />
        }
        
      

        {(process.env.ENV != 'local') &&
        <Script
          id="worker-js-delveinsight"
          strategy='lazyOnload'
          src="/worker.js"
        />
        }
        {
          (router.pathname === '/') ? <Script type="application/ld+json" id="schema-org">
            {`
          {
            "@context": "http://schema.org",
            "@id": "https://www.delveinsight.com/#organization",
            "@type": "Organization",
            "name": "DelveInsight",
            "url": "https://www.delveinsight.com/",
            "logo": "https://www.delveinsight.com/png/delve-logo.png",
            "subOrganization": {
              "@type": "Organization",
              "name": "Pharmdelve",
              "url": "https://pharmdelve.delveinsight.com",
              "@id": "https://pharmdelve.delveinsight.com/#organisation"
            },
            "contactPoint": [
              {
                "@type": "ContactPoint",
                "telephone": "+91-9650213330",
                "contactType": "sales",
                "areaServed": "US"
              }
            ],
            "sameAs": [
              "https://www.youtube.com/channel/UCr6dxyVMHVIcUUfr2Q-q3dQ",
              "https://www.linkedin.com/company/delveinsight-business-research-llp/",
              "https://www.facebook.com/delveinsightindia/",
              "https://twitter.com/delve_insight"
            ]
          }
        `}
          </Script> : null
        }
        <UserProvider>
          <NextNprogress options={{ easing: 'ease', speed: 500 }} color="#0d6efd" />
          <ScrollToTopButton />
          <GoogleReCaptchaProvider  reCaptchaKey={process.env.SITE_KEY_V3}>
            <Component {...pageProps} />
          </GoogleReCaptchaProvider>
        </UserProvider>
      </Provider>
    </>
  );
}


